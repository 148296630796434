import { Table, Button } from 'antd';
import { i18n } from 'src/i18n';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import TableWrapper from 'src/view/shared/styles/TableWrapper';
import SheetService from 'src/modules/sheet/sheetService';
import { DETAIL_TYPE } from 'src/context/selectSheetModalContext';

const SheetSelectList = forwardRef((props: any, ref) => {
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [rows, setRows] = useState([]);
  const resetSelection = () => {
    setRows([]);
    setSelectedRows([]);
    setPagination({
      current: 1,
      pageSize: 10,
      total: 0,
    });
  };
  useImperativeHandle(ref, () => ({
    resetSelection: resetSelection,
  }));
  const fetchSheets = async (newPagination = pagination) => {
    try {
      setLoading(true);
      const offset = (newPagination.current - 1) * newPagination.pageSize;
      const pageSize = newPagination.pageSize;
      const response = await SheetService.list(
        { detailType: sheetType, type: 'LEAD', exclude: props.excludeFields },
        null,
        pageSize,
        offset,
      );
      setRows(response.rows);
      setPagination({
        ...newPagination,
        total: response.count,
        pageSize: newPagination.pageSize,
        current: newPagination.current,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const sheetType = props.visible as DETAIL_TYPE;
  useEffect(() => {
    if (sheetType) {
      fetchSheets();
    }
  }, [sheetType]);

  const handleTableChange = (newPagination) => {
    fetchSheets(newPagination);
  };

  const columns = [
    {
      title: i18n('sheet.fields.keyName'),
      sorter: true,
      dataIndex: 'keyName',
    },
    {
      title: i18n('sheet.fields.description'),
      sorter: true,
      dataIndex: 'description',
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedRows.map((item: any) => item.id),
    onChange: (keys, records) => {
      setSelectedRows(records);
    },
  };

  const handleSave = () => {
    props.handleExtraField(selectedRows);
    props.onCancel();
  };

  return (
    <TableWrapper>
      <Table
        rowKey="id"
        loading={loading}
        columns={columns}
        dataSource={rows}
        pagination={{
          ...pagination,
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
        rowSelection={rowSelection}
        scroll={{ x: true }}
        footer={() => <Button onClick={handleSave}> SPEICHERN</Button>}
      />
    </TableWrapper>
  );
});

export default SheetSelectList;
