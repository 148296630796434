import React, { FC, useContext, useMemo, useState, createContext } from 'react';
import SheetSelectModal from 'src/components/modal/SheetSelectModal/SheetSelectModal';
import { DrawerContext } from './drawerContext';

export enum DETAIL_TYPE {
  CUSTOMER = 'CUSTOMER',
  PRODUCT = 'PRODUCT',
  SHEET = 'SHEET',
}
interface contextDefaultValues {
  doOpenSheetSelectModal: (detailType: DETAIL_TYPE) => void;
}

export const SheetModalContext = createContext({} as contextDefaultValues);

export const SheetModalProvider: FC = (props) => {
  const [sheetSelectModalVisible, setSheetSelectModalVisible] = useState<DETAIL_TYPE | null>(null);
  const { setData, data } = useContext(DrawerContext);

  const handleExtraField = (payload) => {
    const allData = JSON.parse(JSON.stringify(data || {}));
    if (sheetSelectModalVisible === DETAIL_TYPE.CUSTOMER) {
      if (!allData.customerExtraFields) {
        allData.customerExtraFields = [];
      } else {
        allData.customerExtraFields = [
          ...allData.customerExtraFields,
          ...payload.map((sheet) => ({ sheet, value: '' })),
        ];
      }
    } else if (sheetSelectModalVisible === DETAIL_TYPE.PRODUCT) {
      if (!allData.customerExtraFields) {
        allData.productExtraFields = [];
      } else {
        allData.productExtraFields = [...allData.productExtraFields, ...payload.map((sheet) => ({ sheet, value: '' }))];
      }
    } else if (sheetSelectModalVisible === DETAIL_TYPE.SHEET) {
      if (!allData.sheets) {
        allData.sheets = [];
      } else {
        allData.sheets = [...allData.sheets, ...payload.map((sheet) => ({ sheet, value: '' }))];
      }
    }
    setData(allData);
  };

  const excludeFields = useMemo(() => {
    if (sheetSelectModalVisible === DETAIL_TYPE.CUSTOMER) {
      if (!data.customerExtraFields) return [];
      return data.customerExtraFields.map((c) => c.sheet.id);
    } else if (sheetSelectModalVisible === DETAIL_TYPE.PRODUCT) {
      if (!data.productExtraFields) return [];
      return data.productExtraFields.map((c) => c.sheet.id);
    } else if (sheetSelectModalVisible === DETAIL_TYPE.SHEET) {
      if (!data.sheets) return [];
      return data.sheets.map((c) => c.sheet.id);
    }
    return [];
  }, [data.customerExtraFields, data.productExtraFields, sheetSelectModalVisible]);
  const doOpenSheetSelectModal = (detailType: DETAIL_TYPE) => {
    setSheetSelectModalVisible(detailType);
  };

  const doCloseSheetSelectModal = () => {
    setSheetSelectModalVisible(null);
  };

  return (
    <SheetModalContext.Provider value={{ doOpenSheetSelectModal }}>
      <SheetSelectModal
        excludeFields={excludeFields}
        handleExtraField={handleExtraField}
        onClose={doCloseSheetSelectModal}
        visible={sheetSelectModalVisible}
        title="Wählen Sie Eigenschaft aus"
      />
      {props.children}
    </SheetModalContext.Provider>
  );
};
