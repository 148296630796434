import React, { useRef } from 'react';
import { Modal } from 'antd';
import SheetSelectList from 'src/components/pipelines/drawer/SheetSelectList';

const SheetSelectModal = (props) => {
  const { visible, onClose, title, handleExtraField, excludeFields } = props;
  const resetRowsRef = useRef<{ resetSelection: () => {} }>();

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        if (resetRowsRef.current) {
          resetRowsRef.current.resetSelection();
        }
        onClose();
      }}
      maskClosable={false}
      footer={null}
    >
      <h2 style={{ textAlign: 'center' }}>{title}</h2>
      <SheetSelectList
        excludeFields={excludeFields}
        visible={visible}
        ref={resetRowsRef}
        handleExtraField={handleExtraField}
        onCancel={() => {
          if (resetRowsRef.current) {
            resetRowsRef.current.resetSelection();
          }
          onClose();
        }}
      />
    </Modal>
  );
};

export default SheetSelectModal;
