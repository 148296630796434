import { Layout, Menu, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import userActions from 'src/modules/user/form/userFormActions';
import { useDispatch, useSelector } from 'react-redux';
import { EuroCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import SiderWrapper from 'src/view/layout/styles/SiderWrapper';
import authSelectors from 'src/modules/auth/authSelectors';
import authActions from 'src/modules/auth/authActions';
import PermissionChecker from 'src/modules/auth/permissionChecker';
import actions from 'src/modules/layout/layoutActions';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import menus from 'src/view/menus';
import { i18n } from 'src/i18n';
import CCLogo from './logo-dark.png';
import { SiteSettings } from 'src/modules/siteSettings';
import { AdBannerModal } from 'src/components/modal/adBannerModal';
import TenantService from 'src/modules/tenant/tenantService';
import Message from '../shared/message';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import { CONTACT_TYPE } from 'src/modules/common/constants';

const { Sider } = Layout;

const AppMenu = (props) => {
  const [siteBannerVisible, setSiteBannerVisible] = useState(false);
  const dispatch = useDispatch();
  const currentTenant = useSelector(authSelectors.selectCurrentTenant);
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const backupUser = useSelector(authSelectors.selectBackupUser);
  const menuVisible = useSelector(layoutSelectors.selectMenuVisible);
  const toggledSuperAdmin = useSelector(authSelectors.selectToggledSuperAdmin);
  // const logoUrl = useSelector(authSelectors.selectLogoUrl);
  const permissionChecker = new PermissionChecker(currentTenant, currentUser);
  const backupChecker = new PermissionChecker(currentTenant, backupUser);
  const SiteAdBanner = SiteSettings.showAdByTenant(currentTenant);

  const toggleMenuOnResize = () => {
    window.innerWidth < 576 ? hideMenu() : showMenu();
  };

  useEffect(() => {
    toggleMenuOnResize();
    window.addEventListener('resize', toggleMenuOnResize);

    return () => {
      window.removeEventListener('resize', toggleMenuOnResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedKeys = () => {
    const url = props.url;
    const match = menus.find((option) => {
      if (option.exact) {
        return url === option.path;
      }

      return url === option.path || url.startsWith(option.path + '/');
    });
    if (match) {
      return [match.path];
    }
  };

  const hideMenu = () => {
    dispatch(actions.doHideMenu());
  };

  const showMenu = () => {
    dispatch(actions.doShowMenu());
  };

  const match = (permission) => {
    return permissionChecker.match(permission);
  };

  const lockedForCurrentPlan = (permission) => {
    return permissionChecker.lockedForCurrentPlan(permission);
  };

  const isSuperAdmin = backupChecker.isSuperAdmin;

  const handleToggleSuperAdmin = (checked) => {
    dispatch(authActions.doToggleSuperAdmin(checked));
  };

  const handleBannerContactUpdate = async () => {
    try {
      const { id, ...data } = await TenantService.updateContacted(currentTenant.id, {
        contactType: CONTACT_TYPE.AD,
        bannerType: SiteAdBanner.type,
      });
      if (id) {
        Message.success(i18n('user.userBannerContactedSuccess'));
        dispatch(authActions.doSelectTenant({ id, ...data }));
      }
    } catch (err) {
      Message.error('Beim Kontaktieren ist ein Fehler aufgetreten');
    }
  };
  return (
    <SiderWrapper
      style={{
        display: menuVisible ? 'block' : 'none',
      }}
    >
      <Sider theme="light" trigger={null}>
        <div className="logo" style={{ height: 'auto' }}>
          <Link to="/">
            <img src={CCLogo} width="164px" alt={i18n('app.title')} />
          </Link>
        </div>

        <Menu theme="light" mode="inline" selectedKeys={selectedKeys()}>
          {menus
            .filter((menu) => match(menu.permissionRequired))
            .map((menu) => (
              <Menu.Item key={menu.path}>
                <Link to={menu.path}>
                  {menu.icon}
                  <span>{menu.label}</span>
                  {menu.badge || null}
                </Link>
              </Menu.Item>
            ))}

          {menus
            .filter((menu) => lockedForCurrentPlan(menu.permissionRequired))
            .map((menu) => (
              <Menu.Item
                style={{
                  cursor: 'auto',
                  opacity: 0.3,
                }}
                key={menu.path}
              >
                {menu.icon}
                <span>{menu.label}</span>
              </Menu.Item>
            ))}
          <Menu.Item>
            <EuroCircleOutlined />
            <span>
              <a
                style={{ color: '#008e17' }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://am-beratung.de/partnerprogramm"
              >
                Kunden werben Kunden
              </a>
            </span>
          </Menu.Item>
          <Menu.Item>
            <PlayCircleOutlined />
            <span>
              <a
                style={{ color: '#008e17' }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://partner-bereich.mymemberspot.de/"
              >
                Videokurs
              </a>
            </span>
          </Menu.Item>
          {isSuperAdmin ? (
            <Menu.Item>
              Admin View: <Switch size="small" checked={toggledSuperAdmin} onChange={handleToggleSuperAdmin} />
            </Menu.Item>
          ) : null}

          {SiteAdBanner && (
            <>
              <div style={{ marginLeft: 24, width: 220, cursor: 'pointer' }} onClick={() => setSiteBannerVisible(true)}>
                <img width={'100%'} key={1} src={SiteAdBanner.url} alt={'ad'} />
              </div>
              <AdBannerModal
                contactedOn={currentTenant.adContactedOn || currentTenant.createdAt}
                isContacted={currentTenant.adContacted}
                type={SiteAdBanner.type}
                visible={siteBannerVisible}
                onClose={() => setSiteBannerVisible(false)}
                onOk={handleBannerContactUpdate}
              />
            </>
          )}
        </Menu>
      </Sider>
    </SiderWrapper>
  );
};

export default AppMenu;
