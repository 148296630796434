import React, { createContext } from 'react';
import { FC, useState } from 'react';

interface contextDefaultValues {
  data: any | undefined;
  setData: (value: any) => any;
}

export const DrawerContext = createContext({} as contextDefaultValues);

export const DrawerProvider: FC = ({ children }) => {
  const [dataState, setDataState] = useState<any | undefined>(undefined);

  const data = dataState;
  const setData = (value: any) => {
    setDataState(value);
  };

  return (
    <DrawerContext.Provider
      value={{
        data,
        setData,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};
